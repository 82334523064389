@charset "UTF-8";

@import '../@tmds/tmdscolor';
@import '../@tmds/tmdsfonts';
@import '../@tmds/variables/color';

@import '../basics/variables';
@import '../basics/mixins';
@import '../basics/functions';
@import '../basics/extends';
@import '../basics/keyframes';
@import '../basics/tmdscolors';
@import '../basics/publictranscolors';

.custom_select_wrapper {
  width: 100%;
  position: fixed;
  // bottom: 0;
  visibility: hidden;
  background: $wb-white;
  z-index: $Z-custom-layer;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
  padding-top: 20px;
  bottom: 0;
  transform: translateY(100%);
  transition: transform ease-in-out 0.2s;
  @include font-size-18();

  &:active {
    &::before {
      z-index: $Z-ignore;
    }
  }

  &::before {
    content: ' ';
    position: absolute;
    width: 100%;
    height: 20px;
    top: 20px;
    left: 0;
    background: linear-gradient(0deg, $C-white-tr-00 10%, $wb-white 100%);
    z-index: $Z-gradient;
  }

  &.is_open {
    visibility: visible;
    transform: translateY(0%);
  }

  .list {
    max-height: 430px;

    &.multiple {
      max-height: 420px;
      margin-bottom: 68px; // 16 + 52 하단 버튼영역
    }
  }

  .list {
    position: relative;

    @include scroll-wrap(y);

    .item {
      position: relative;
      height: 56px;
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 20px;

      @include touch-guide(5px);

      .click_area {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      .label {
        flex-grow: 1;
        @include font-size-18();
      }

      .icon {
        width: 34px;
        height: 34px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        flex-grow: 0;
        margin: 2px 8px 2px 0px;

        img {
          max-width: 34px;
          max-height: 34px;
        }
      }

      .check_icon {
        width: 24px;
        height: 24px;
        display: none;

        &.is_multiple {
          width: 20px;
          height: 20px;
        }
      }

      &.on {
        .label {
          color: $tmobi-blue-500;
          @include font-size-18-bold();
        }

        .check_icon {
          display: initial;
          @include override-svg($tmobi-blue-500, 24px, 24px);
        }
      }

      &:last-child {
        margin-bottom: 20px;
      }
    }

    &.multiple {
      .item {
        &.on {
          .label {
            color: $gray-900;
            @include font-size-18();
          }
        }
        .check_icon {
          display: initial;

          svg {
            width: 20px;
            height: 20px;
          }
        }
      }
    }
  }

  .bottom_area {
    position: absolute;
    bottom: 0;
    width: 100%;
    background: $wb-white;

    &::before {
      content: ' ';
      position: absolute;
      width: 100%;
      height: 20px;
      top: -20px;
      background: linear-gradient(180deg, $C-white-tr-00 0%, $wb-white 100%);
    }

    .buttons {
      padding: 0 20px 16px 20px;
      display: flex;

      .reset {
        margin-right: 8px;
        background-color: $gray-100;
        flex-grow: 1;
        border-radius: 8px;
      }

      .confirm {
        border: none;
        height: 52px;
        border-radius: 8px;
        background-color: $tmobi-blue-500;
        text-align: center;
        color: $wb-white;
        flex-grow: 2;

        position: relative;
        @include touch-guide(5px);
        @include font-size-18();
      }
    }
  }
}
body[data-orientation='landscape'] {
  .custom_select_wrapper {
    width: 360px;
    right: 10px;

    .list {
      max-height: calc(100vh - 22px);

      &.multiple {
        max-height: calc(100vh - 94px);
      }
    }
  }
}

body[data-safe-area='true'] {
  .custom_select_wrapper {
    .list {
      .item {
        &:last-child {
          margin-bottom: calc(20px + env(safe-area-inset-bottom));
        }
      }
    }
  }
}

.focus_label {
  position: fixed;
  right: 200vw;
}
